import {BasicsClass} from "@/common/BasicsClass";

export default class molisTableManage extends BasicsClass {
    public tableManageList(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        this.BasicPost(
            "/admin/product/index",
            params,
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public tableManageAdd(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        this.BasicPost(
            "/admin/product/add",
            params,
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public tableManageEdit(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        this.BasicPost(
            "/admin/product/edit",
            params,
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public tableManageDetail(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        this.BasicPost(
            "/admin/product/detail",
            params,
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public tableManageDel(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        this.BasicPost(
            "/admin/product/del",
            params,
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

}
