
































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import molisTableManage from "@/common/api/molisTableManage";
import {Util} from "@/common/util";
import {excuteDownloadExport2Excel} from "@/common/xlsx";
import { CommonApi } from "@/common/api/common";

const shareConfirmOptions = {
  lockScroll: true,
  closeOnClickModal: false,
  closeOnPressEscape: false,
  confirmButtonText: "确定",
  cancelButtonText: "取消",
};

@Component({})
export default class UserList extends Vue {
  constructor(){
    super();
    this.commonApi = new CommonApi();
  }
  private molisTableManage: any = new molisTableManage();
  private dialogVisible: boolean = false;
  private imagesArray:any = []
  private imagesDetailsArray:any = []
  private commonApi: any;
  private operateType:number = 1 // 1 新增 2 编辑
  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.molisTableManage.tableManageList(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }


  private  rules = {
    name: [
      { required: true, message: '请输入品牌名称', trigger: 'blur' },
    ],
    inner_size: [
      { required: true, message: '请输入内径大小', trigger: 'blur' },
    ],
    category: [
      { required: true, message: '请输入类别', trigger: 'blur' }
    ],
    enclosure: [
      { required: true, message: '请输入使用面积大小', trigger: 'blur' },
    ],
    product_model: [
      { required: true, message: '请输入型号', trigger: 'blur' },
    ],
    external_size: [
      { required: true, message: '请输入外径尺寸', trigger: 'blur' },
    ],
    product_model_number: [
      { required: true, message: '请输入型号编号', trigger: 'blur' },
    ],
    product_img: [
      { validator: this.checkProductImg,trigger: 'change' },
    ],
    product_detail_img: [
      { validator: this.checkProductDetailImg, trigger: 'change' },
    ],
  }

  private addform:any = {
    name:'',
    product_model:'',
    product_model_number:'',
    inner_size:'',
    external_size:'',
    enclosure:'',
    category:'',
    product_img:'',
    product_detail_img:''
  };
  private checkProductImg(rule, value, callback) {
    if (!this.imagesArray.length || !this.addform.product_img) {
      callback(new Error('请上传产品封面图'));
    } else {
      callback();
    }
  };
  private checkProductDetailImg(rule, value, callback) {
    if (!this.imagesDetailsArray.length || !this.addform.product_detail_img) {
      callback(new Error('请上传产品详情图'));
    } else {
      callback();
    }
  };
  private onAddForm(type: number, row: any = {}) {
    this.operateType = type
    this.addform = new Util().clearObject(this.addform);
    this.imagesArray = []
    this.imagesDetailsArray = []
    if (this.operateType === 2) {
      this.addform['id'] = row.id
      this.molisTableManage.tableManageDetail({id: row.id}, (res: any) => {
        console.log('产品详情666', res)
        const {data: result} = res
        this.addform = result
        this.$set(this.imagesArray, 0, {uid: result.product_img, url: result.product_img_url})
        this.$set(this.imagesDetailsArray, 0, {uid: result.product_detail_img, url: result.product_detail_img_url})
        this.dialogVisible = true;
      })
      return
    }
    this.dialogVisible = true;
  }

  // 删除 banner
  handleDelete(row: any) {
    this.$confirm("确定删除该产品吗", "", {...shareConfirmOptions,}).then(() => {
      this.molisTableManage.tableManageDel(
          {id: row.id,},
          () => {
            this.$message.success('删除成功')
            this.getTable();
          },
          () => {}
      );
    });
  }


  private handleClose() {
    this.dialogVisible = false;
  }
  private add_submit(): void {
    this.$refs['ruleForm'].validate((valid) => {
      if (valid) {
        console.log(this.addform,'提交参数')
        if(this.operateType === 1){
          this.molisTableManage.tableManageAdd(this.addform, (res: any) => {
            this.dialogVisible = false;
            this.$message.success('新增成功')
            this.getTable()
          })
        }else{
          this.molisTableManage.tableManageEdit(this.addform, (res: any) => {
            this.dialogVisible = false;
            this.$message.success('编辑成功')
            this.getTable()
          })
        }

      } else {
        console.log('未通过校验')
      }
    });

  }
  private handleFileRemove(type:number){
    if(type===1){
      this.imagesArray = []
      this.addform.product_img = ''
    }else{
      this.imagesDetailsArray = []
      this.addform.product_detail_img = ''
    }
  }
  private handleUploadImage(file: any, fileList: any[],type:number) {
    console.log(file, "file",fileList);
    this.commonApi.getOss(
        file instanceof File ? file : file.raw,
        (res: any) => {
          console.log(res, "res 图片上传成功");
          const { data } = res;
          const { data: uploadImgObjWrap } = data;
          const { item: uploadImgObj } = uploadImgObjWrap;
          const { id, url } = uploadImgObj;

          if(type===1){
            this.$set(this.addform, "product_img", id);
            this.$set(this.imagesArray,0,{uid:id,url:url})
            // this.$refs['ruleForm'].validate(valid=>{})
            this.$refs.ruleForm.validateField('product_img');
          }else{
            this.$set(this.addform, "product_detail_img", id);
            this.$set(this.imagesDetailsArray,0,{uid:id,url:url})
            this.$refs.ruleForm.validateField('product_detail_img');
          }

        },
        () => {
          this.$message.error("图片上传失败");
        }
    );
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
