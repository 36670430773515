import {BasicsClass} from "@/common/BasicsClass";

export default class molisTableNumber extends BasicsClass {
    public tableList(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        this.BasicPost(
            "/admin/product/bindList",
            params,
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public tableExportList(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        this.BasicPost(
            "/admin/product/exportBindList",
            params,
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
