
























































import {Component, Prop, Vue} from "vue-property-decorator";
import {Login, login} from "@/common/login/login";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {Util} from "@/common/util";
const defaultCountDownNum: number = 60
@Component
export default class HelloWorld extends Vue {
  private param: login = {
    phone: '',
    code: ''
  }
  private isDisabledGetVertifyCodeBtn: boolean = false
  private isGetVertifyCodeBtnLoading: boolean = false
  private loginF: Login;
  private deepLoopUtil: any;
  private curCountDown: number = 0;
  private countDonwTimer: any

  constructor() {
    super();

    this.loginF = new Login();

    this.deepLoopUtil = new DeepLoop();
  }
  private handleGetVertifyCode() {
    const param = this.param

    const params: any = {

    }

    if (!param.phone) {
      this.$message.error('请输入手机号')
      return
    }

    params.phone = param.phone

    this.isGetVertifyCodeBtnLoading = true

    this.loginF.getLoginVertifyCode(
        params,
        () => {
          this.$message.success('短信发送成功')

          this.isGetVertifyCodeBtnLoading = false

          this.handleStartCountDown()
        },
        () => {
          this.isGetVertifyCodeBtnLoading = false
        }
    )
  }

  private handleStartCountDown() {
    clearTimeout(this.countDonwTimer)
    const curCountDown = defaultCountDownNum - 1

    this.curCountDown = curCountDown

    const startCountDownTimeout = (count: number) => {
      if (count > 0) {
        this.countDonwTimer = setTimeout(() => {
          this.curCountDown = count - 1

          startCountDownTimeout(count - 1)
        }, 1000)
      } else {
        this.isDisabledGetVertifyCodeBtn = false
      }
    }

    startCountDownTimeout(curCountDown)

    this.isDisabledGetVertifyCodeBtn = true
  }

  private submitForm() {
    this.submitErrMsg = "";

    if (!this.param.phone) {
      this.submitErrMsg = "请输入手机号";
      return;
    } else if (!this.param.code) {
      this.submitErrMsg = "请输入验证码";
      return;
    }

    const param = this.deepLoopUtil.deepCopy(this.param);
    this.loginF.submitForm(
        param,
        (res: any) => {
          const memu: any = [
            {
              id: `molis_table_manage`,
              icon: require("@/assets/home/pingtaiguanli@2x.png"),
              name: "麦力仕球桌管理",
              url: "/molis_table_manage",
            },
            {
              id: `molis_table_number`,
              icon: require("@/assets/home/pingtaiguanli@2x.png"),
              name: "麦力仕球桌编号",
              url: "/molis_table_number",
            },
          ];
          localStorage.setItem("menus", JSON.stringify(memu));
          this.$router.push("/molis_table_manage");
        },
        (msg: string) => {
          this.submitErrMsg = msg;
        }
    );
  }

  private submitErrMsg: string = "";

  private created() {
    localStorage.clear();
    // console.log(location.href.split("=")[1].split("#")[0]);
  }
  private deactivated() {
    clearTimeout(this.countDonwTimer)
  }
}
