
























































































import {Component, Vue} from "vue-property-decorator";
import molisTableNumber from "@/common/api/molisTableNumber";
import {Util} from "@/common/util";
import {excuteDownloadExport2Excel} from "@/common/xlsx";
import { CommonApi } from "@/common/api/common";


@Component({})
export default class UserList extends Vue {
  constructor(){
    super();
    this.commonApi = new CommonApi();
  }
  private molisTableNumber: any = new molisTableNumber();

  private commonApi: any;

  private form:any = {
    serial_no:'',
    bind_status:0,
    start_date:null,
    end_date:null
  }
  private time:any = []
  private statusArrays = [
    {value: 0, label: '全部'},
    {value: 1, label: '未绑定'},
    {value: 2, label: '已绑定'},
    {value: 3, label: '已作废'}
  ]
  statusObject = {
    1:'未绑定',
    2:'已绑定',
    3:'已作废',
  }
  private activated(): void {
    this.getTable();
  }
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      this.form.start_date = startTime;
      this.form.end_date = endTime;
    }
    this.getTable();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.time = []
  }
  private getTable(): void {
    this.molisTableNumber.tableList(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
          ...this.form,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 渠道字段名 文案对应关系
  private merchantExcelList2ch: any = {
    batch_no: "批次",
    serial_no: "球桌编号",
    product_model: "类型",
    bind_status: "绑定状态",
    bind_time: "绑定时间",
    bind_person_name: "操作人",
  };
  // 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "batch_no",
    "serial_no",
    "product_model",
    "bind_status",
    "bind_time",
    "bind_person_name",
  ];

  // 获取excelJSON数据
  private getExcelJSONDataByList(list: any[], sortKey: string[], map2ch: any) {
    const newList = list.map((eachItem) => {
      const newItem: any = {};

      for (const key of sortKey) {
        const val = eachItem[key];

        const chKey = map2ch[key];
        if (val) {
          newItem[chKey] = val;
        } else {
          newItem[chKey] = "";
        }
      }

      return newItem;
    });

    return newList;
  }

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserTableListParams("exportExcel");
    if (params === null) {
      return false;
    }
    this.downloadExcelLoading = true;
    this.molisTableNumber.tableExportList(
        {...this.form},
        (res: any) => {
          const start_date: string = Util.dateUtil(this.time[0]);
          const end_date: string = Util.dateUtil(this.time[1]);
          const filename = `球桌编号 ${start_date}到${end_date}.xlsx`;
          const originList = res.data.list || [];

          const newList = Array.isArray(originList)
              ? originList.map(item => {
                item.bind_status =  this.statusObject[item.bind_status]
                return item;
              })
              : [];

          if (newList.length > 0) {
            const merchantExcelSortList = this.merchantExcelSortList;

            const merchantExcelList2ch = this.merchantExcelList2ch;

            const excelJSONData = this.getExcelJSONDataByList(
                newList,
                merchantExcelSortList,
                merchantExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }


          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }

  private getUserTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;
    const tablePage = this.tablePage;
    const {
      serial_no, // 手机号
      bind_status, // 状态
      start_date, // 开始时间
      end_date, // 结束时间
    } = form;

    const { pageNo, pageSize } = tablePage ;

    const outerParams: any = {};

    if (start_date) {
      outerParams.start_date = start_date;
    }

    if (end_date) {
      outerParams.end_date = end_date;
    }

    if (serial_no) {
      outerParams.serial_no = serial_no;
    }

    if (bind_status) {
      outerParams.bind_status = bind_status;
    }

    if (Array.isArray(this.time) && this.time.length === 2) {
      const start_date = Util.dateTimeUtilnohms(this.time[0]);
      const end_date = Util.dateTimeUtilnohms(this.time[1]);
      outerParams.start_date = start_date;
      outerParams.end_date = end_date;
    }

    if (mode == "exportExcel" && !(outerParams.start_date && outerParams.end_date)) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;
      outerParams.limit = pageSize;
    }
    return outerParams;
  }

  /** end 导出excel */
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
